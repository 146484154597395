import {useParams} from "react-router-dom";

export function ProductMissing() {
  const params = useParams()
  return (
    <p>
      Product couldn't be found...
      Searched for {params['productId']}
    </p>
  );
}