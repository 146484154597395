import * as E from 'fp-ts/Either';
import { Form, redirect } from "react-router-dom";
import { ActionFunctionArgs } from "@remix-run/router/utils";

export function parseSearch(value: string): string {
  const productIdE = E.tryCatch(() => {
    const url = new URL(value)
    const id = url.searchParams.get("id")
    if (id == null) throw Error("Missing id param")
    return id
  }, _ => "Invalid URL");
  return E.getOrElse(_ => value)(productIdE);
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData()
  const searchValue = formData.get("q") as string ?? ""
  const productId = parseSearch(searchValue)

  const input = document.getElementById('search-bar') as HTMLInputElement
  input.value = productId

  return redirect(`products/${productId}`)
}

export function Search() {
  return (
    <div className="flex-fill">
      <Form role="search" method="post">
        <div className="input-group">
          <input
            aria-label="search products"
            type="text"
            className="form-control"
            placeholder="Enter Product ID or URL"
            name="q"
            id="search-bar"
          />
          <button type="submit" className="btn btn-outline-primary" aria-label="submit search">
              <i className="bi-search"></i>
          </button>
        </div>
      </Form>
    </div>
  );
}