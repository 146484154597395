import * as Config from "./Config";
import React from "react";
import { Await, useLoaderData, defer, Link } from "react-router-dom";
import {
  Chart as ChartJS, CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { LoaderFunctionArgs } from "@remix-run/router/utils";
import Spinner from "react-bootstrap/Spinner";
import { logEvent } from "firebase/analytics";
import analytics from "./firebase";
import { eachDayOfInterval } from 'date-fns';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
);

export type Product = {
  boxId: string
  boxName: string
  categoryName: string
  superCatName: string
  imageUrls: {
    large: string
    medium: string
    small: string
  },
  latestPrice: Price | null,
}

export type Price = {
  cashPrice: number
  exchangePrice: number
  sellPrice: number
  productId: string
  firstSeen: number
  lastSeen: number
}

export async function getProduct(productId: string): Promise<Product> {
  const product = await fetch(`${Config.apiUrl}/product/${productId}`);
  const json = await product.json();
  return json as Product
}

export async function getPrices(productId: string): Promise<Price[]> {
  const product = await fetch(`${Config.apiUrl}/prices/${productId}`)
  return await product.json()
}

type LoaderData = {
  product: Promise<Product>,
  prices: Promise<Price[]>
}

export async function loader({ params }: LoaderFunctionArgs) {
  const productId = params['productId'] || ""

  logEvent(analytics.analytics, 'product_view', {
    productId: productId
  })

  return defer({
    product: getProduct(productId),
    prices: getPrices(productId)
  } as LoaderData)
}

function renderLoader() {
  return (
    <div className="d-flex justify-content-center">
      <div className="m-3">
        <Spinner animation="grow"></Spinner>
      </div>
    </div>
  );
}

function renderPriceChart(prices: Price[]) {
  const pricesFinal = prices.flatMap(p => {
    const interval = eachDayOfInterval({
      start: new Date(p.firstSeen),
      end: new Date(p.lastSeen),
    })
    return interval.map(date => ({
      date,
      sellPrice: p.sellPrice,
      exchangePrice: p.exchangePrice,
      cashPrice: p.cashPrice,
    }));
  })

  const labels: string[] = pricesFinal.map(p => p.date.toLocaleDateString())
  const sellPrices = pricesFinal.map(p => p.sellPrice)
  const exchangePrices = pricesFinal.map(p => p.exchangePrice)
  const cashPrices = pricesFinal.map(p => p.cashPrice)

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Sell price',
        data: sellPrices
      },
      {
        label: 'Exchange price',
        data: exchangePrices
      },
      {
        label: 'Cash price',
        data: cashPrices
      }
    ]
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Item prices over time',
      },
    },
  }

  return (
    <Line options={options} data={chartData} style={({height: "45vh"})}></Line>
  );
}

export function ProductView() {
  const data = useLoaderData() as LoaderData

  return (
    <div className="container">
      <React.Suspense
        fallback={renderLoader()}>
        <Await
          resolve={data.product}
          errorElement={<p>Failed to load product</p>}>
          {(product: Product) => (
            <div className="row">
              <div className="col-4">
                <img src={product.imageUrls.large} alt="" className="img-fluid"/>
              </div>
              <div className="col-8">
                <h1>{ product.boxName }</h1>
                <p>
                  <Link to={`/products/super-category/${product.superCatName}`}>{ product.superCatName }</Link>
                  &nbsp;/&nbsp;
                  <Link to={`/products/category/${product.categoryName}`}>{ product.categoryName }</Link>
                </p>
                <a href={`https://uk.webuy.com/product-detail/?id=${product.boxId}`} target="_blank" rel="noreferrer" className="btn btn-outline-primary">
                  View on CeX
                </a>
              </div>
            </div>
          )}
        </Await>
      </React.Suspense>
      <div className="row">
        <div className="col-12">
          <React.Suspense
            fallback={renderLoader()}>
            <Await
              resolve={data.prices}
              errorElement={<p>Failed to load prices</p>}>
              {renderPriceChart}
            </Await>
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}