import React from 'react';
import llama from './llama.png';

import { Search } from "./Search";
import Button from "react-bootstrap/Button";

import { Link, Outlet } from "react-router-dom";

export function loader() {
  return null
}

export function Root() {
  return (
    <main className="py-4">
      <div className="container">
        <div className="d-flex flex-row align-items-center">
          <Link to={`/`}>
            <img src={llama} className="bi me-3" height="50" alt="LlamaLlamaLlama" />
          </Link>
          <Search />
          <div className="ps-2">
            <div className="d-none d-sm-none">
              <Link to={"top-products"}>
                <Button className="mx-1">Top Products</Button>
              </Link>
              <Link to={"biggest-price-drops"}>
                <Button className="mx-1">Biggest Drops</Button>
              </Link>
              <Link to={"about"}>
                <Button className="mx-1">About</Button>
              </Link>
            </div>
            <div className="d-block d-flex float-end">
              <Link to={"top-products"}>
                <Button className="mx-1">
                  <i className="bi-award"></i>
                </Button>
              </Link>
              <Link to={"biggest-price-drops"}>
                <Button className="mx-1">
                  <i className="bi-graph-down-arrow"></i>
                </Button>
              </Link>
              <Link to={"about"}>
                <Button className="mx-1">
                  <i className="bi-info-circle"></i>
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="my-3">
            <Outlet />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p className="credit">
              <a href="https://www.flaticon.com/free-icons/llama" title="llama icons" target="_blank" rel="noreferrer">Llama icons created by Freepik - Flaticon</a>
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Root;
