import * as Config from "./Config";
import {Link, useLoaderData, defer, Await} from "react-router-dom";
import { Product } from "./Product";
import Spinner from "react-bootstrap/Spinner";
import React from "react";

async function getTopProducts() {
  const top = await fetch(`${Config.apiUrl}/top-products`)
  return await top.json()
}

type LoaderData = {
  top: Promise<Product[]>
}

export async function loader() {
  return defer({
    top: getTopProducts(),
  } as LoaderData)
}

function renderLoader() {
  return (
    <div className="d-flex justify-content-center">
      <div className="m-3">
        <Spinner animation="grow"></Spinner>
      </div>
    </div>
  );
}

export function TopProducts() {
  const data = useLoaderData() as LoaderData
  return (
    <div>
      <div>
        <h1>
          Top Products
        </h1>
        <p className="lead">Our most popular products...</p>
      </div>
      <React.Suspense
        fallback={renderLoader()}>
        <Await
          resolve={data.top}
          errorElement={<p>Failed to top products</p>}>
          {(top: Product[]) => {
            return (
              <div className="row row-cols-2 row-cols-md-4 row-cols-lg-5 g-4">
                {
                  top.map(product => {
                    return (
                      <div className="col" key={product.boxId}>
                        <div className="card h-100">
                          <Link to={`/products/${product.boxId}`} className="text-theme-color">
                            <img src={product.imageUrls.large} className="card-img-top" alt={product.boxName} />
                          </Link>
                          <div className="card-body">
                            <h5 className="card-title">
                              <Link to={`/products/${product.boxId}`} className="text-theme-color">
                                {product.boxName}
                              </Link>
                            </h5>
                            <div className="card-text">
                              <ul className="list-group">
                                <div className="list-group-item">
                                  Buy £{product?.latestPrice?.sellPrice.toFixed(2)}
                                </div>
                                <div className="list-group-item">
                                  Sell £{product?.latestPrice?.cashPrice.toFixed(2)}
                                </div>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            )
          }}
        </Await>
      </React.Suspense>
    </div>
  );
}