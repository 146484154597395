import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCpbjfPi77J4j5kfssT8x6wBmps6A8dNEw",
  authDomain: "cex-tracker-8f319.firebaseapp.com",
  projectId: "cex-tracker-8f319",
  storageBucket: "cex-tracker-8f319.appspot.com",
  messagingSenderId: "53376514705",
  appId: "1:53376514705:web:b5102820deea7737625d30",
  measurementId: "G-7TLEE7EKTD",
};

const root = initializeApp(firebaseConfig)
const analytics = getAnalytics(root)

const out = {
  analytics
}

export default out