import React from 'react';
import ReactDOM from 'react-dom/client';
import './custom.scss';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import reportWebVitals from './reportWebVitals';
import analytics from "./firebase";
import { logEvent } from "firebase/analytics";
import { Metric } from "web-vitals";

import ErrorPage from "./ErrorPage";
import { Root, loader as rootLoader } from './Root';
import { ProductView, loader as productLoader } from "./Product";
import { TopProducts, loader as topProductsLoader } from "./TopProducts";
import { action as searchAction } from "./Search";
import { ProductMissing } from "./ProductMissing";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    action: searchAction,
    loader: rootLoader,
    children: [
      {
        path: "products/:productId",
        element: <ProductView />,
        errorElement: <ProductMissing />,
        loader: productLoader,
      },
      {
        path: "top-products",
        element: <TopProducts />,
        loader: topProductsLoader,
      }
    ]
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
function sendToAnalytics({ id, name, value }: Metric) {
  logEvent(analytics.analytics, 'event', {
    eventCategory: 'Web Vitals',
    eventAction: name,
    eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    eventLabel: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate
  })
}

reportWebVitals(sendToAnalytics);
